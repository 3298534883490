<template>
  <transition
    v-if="showModal"
    name="custom-classes-transition"
    enter-active-class="animated bounceInLeft"
    leave-active-class="animated bounceOutRight"
  >
    <div class="detail-modal">
      <div class="detail-modal-inner">
        <div class="menu">
          <div class="close-show-detail" @click="closeModal">
            <eva-icon
              name="close-circle"
              fill="#FFF"
              width="50"
              height="50"
            ></eva-icon>
          </div>
        </div>
        <div class="page-wrap">
          <transition
            name="custom-classes-transition"
            enter-active-class="animated flipInY"
          >
            <perfect-scrollbar class="page">
              <section class="page-inner">
                <form class="demand-form">
                  <div class="tab-content">
                    <div class="tab-section tab-01">
                      <div class="tab-name">
                        <div class="name-innner">健康疫調問卷</div>
                      </div>
                      <table class="table table-bordered">
                        <tbody>
                          <tr>
                            <td width="15%" class="text-right">
                              <span>公司名</span>
                            </td>
                            <td width="35%">
                              {{ questionnaire.CompanyName }}
                            </td>
                            <td width="15%" class="text-right">
                              <span>姓名</span>
                            </td>
                            <td width="35%">
                              <span>{{
                                questionnaire.Name
                              }}</span>
                            </td>
                          </tr>

                          <tr>
                            <td width="15%" class="text-right">
                              <span>訪客類別</span>
                            </td>
                            <td width="35%">
                              {{ questionnaire.VisitorType }}
                            </td>
                            <td width="15%" class="text-right">
                              <span>駐廠廠商</span>
                            </td>
                            <td width="35%">
                              {{ questionnaire.Vendor }}
                            </td>
                          </tr>

                          <tr>
                            <td width="15%" class="text-right">
                              <span>身分證字號</span> <br>
                              <span>/ 護照號碼</span>
                            </td>
                            <td width="35%">
                              {{ questionnaire.IdNumber }}
                            </td>
                            <td width="15%" class="text-right">
                              <span>黃卡編號</span>
                            </td>
                            <td width="35%">
                              {{ questionnaire.NoCardId }}
                            </td>
                          </tr>

                          <tr>
                            <td width="15%" class="text-right">
                              <span>連絡電話</span>
                            </td>
                            <td width="35%">
                              {{ questionnaire.TelephoneNumber }}
                            </td>
                            <td width="15%" class="text-right">
                              <span>居住地</span>
                            </td>
                            <td width="35%">
                              {{ questionnaire.CurrentResidencePlace }}
                            </td>
                          </tr>
                     

                          <tr>
                            <td colspan="1" class="text-right">
                              <span>足跡 </span>
                            </td>
                            <td colspan="3">
                              <!-- {{ questionnaire.DailySurvey }} -->
                              <span v-for="(item, index) in questionnaire.Footprint" :key="item">
                                <span v-if="index > 0">、</span>
                                {{item}}
                              </span>
                            </td>
                          </tr>

                          <tr v-for="item in details" :key="item.Guid">
                            <td colspan="1" class="text-right">
                              <span>
                                    <span v-if="lang === 'zh'">{{ item.FieldName_zhTW }}</span>
                                    <span v-if="lang === 'en'">{{ item.FieldName_enUS }}</span> 
                              </span>
                            </td>
                            <td colspan="3">
                              <span>
                                {{ item.Value }}
                              </span>
                            </td>
                          </tr>

                          
                        </tbody>
                      </table>
                    </div>
                  </div>
                </form>
              </section>
            </perfect-scrollbar>
          </transition>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

import { apiGetExternalHealthQuestionnaire } from '@/apis/questionnaire'

export default {
  name: "Detail",
  data() {
    return {
      showModal: false,

      DriverLicenseStatus: false,
      OfficeHourStatus: false,
      LanguageStatus: false,
      DialectStatus: false,
      demandDetail: [],

      questionnaire: {},
      details: [],
    };
  },

  filters: {
    moment: function(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    demandStatus: function(status) {
      switch (status) {
        case "New":
          return "新建";
        case "Pending":
          return "待決";
        case "Approved":
          return "同意";
        case "Suspend":
          return "暫停";
        case "Cancelled":
          return "作廢";
      }
    },
  },

  computed: {
    ...mapGetters(["lang", "watchReportGuid"]),
  },

  watch: {
    watchReportGuid(guid) {
      if(guid) {
        this.getExternalHealthQuestionnaire(guid)
      }
    },
  },

  methods: {
    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },

    closeModal() {
      this.$store.dispatch("updateWatchGuid", '');

      this.showModal = false;
    },

    getExternalHealthQuestionnaire(Guid) {
        apiGetExternalHealthQuestionnaire({
            guid: Guid
        }).then((response) => {

            if(response.status === 200) {
                this.questionnaire = response.data

                this.details = []

                response.data.DataContent.forEach(category => {
                    this.details = [ ...this.details, ...category.ContentJson ]
                })

                this.details = this.details.filter(item => {
                    if(item.FieldType !== 'Remark') {
                        return item
                    }
                })

                this.showModal = true;
            }
            
        })
    },

    dateFormat: function(date) {
      return moment(date).format("YYYY-MM-DD");
    },

  },

  destroyed() {
    this.showModal = false;
    // this.$store.commit("OpenDemandDetailModal", null);
  },
};
</script>

<style lang="scss" scoped>
.detail-modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    background-color: rgba(0,0,0,.9);
}

.detail-modal {
  z-index: 2000;
  .detail-modal-inner {
    .menu {
      width: 0%;
    }
    .page-wrap {
      background-image: none;
      .page {
        max-width: 960px;
        margin: 25px auto;
      }
    }
    pre {
      white-space: pre-wrap;
      word-wrap: break-word;
    }
  }
}

.demand-form {
  vertical-align: middle;
}

.test {
  background-color: gray;
  color: white;
}
</style>
