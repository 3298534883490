<template>
    <div class="container-fluid">
        <div class="card search-card">
            <div class="card-body">
                <ValidationObserver v-slot="{ handleSubmit }">
                    <div class="form-row">
                        <div class="col-sm-12 col-md-2">
                            <div class="form-group">
                                <label for="ManpowerDemandNo">
                                黃卡編號
                                </label>
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="請輸入黃卡編號"
                                    v-model="searchInfo.NoCardId"
                                />
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-2">
                            <div class="form-group">
                                <label>公司名</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="請輸入公司名"
                                    v-model="searchInfo.CompanyName"
                                />
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-2">
                            <div class="form-group">
                                <label>姓名</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="請輸入姓名"
                                    v-model="searchInfo.Name"
                                />
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-2">
                            <div class="form-group">
                                <label>身分證字號/護照號碼</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="請輸入身分證字號/護照號碼"
                                    v-model="searchInfo.IdNumber"
                                />
                            </div>
                        </div>
                        <!-- <div class="col-sm-12 col-md-2">
                            <div class="form-group">
                                <label>連絡電話</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="請輸入連絡電話"
                                    v-model="searchInfo.TelephoneNumber"
                                />
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-2">
                            <div class="form-group">
                                <label>居住地</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="請輸入居住地"
                                    v-model="searchInfo.CurrentResidencePlace"
                                />
                            </div>
                        </div> -->

                        <div class="col-sm-12 col-md-2">
                            <div class="form-group">
                                <label>訪客類別</label>
                                <select class="form-control" v-model="searchInfo.VisitorType">
                                    <option value=""></option>
                                    <option v-for="item in visitorTypes" :key="item.OptionsName_zhTW" :value="item.OptionsName_zhTW">{{ item.OptionsName_zhTW }}</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-2">
                            <div class="form-group">
                                <label>駐廠廠商</label>
                                <select class="form-control" v-model="searchInfo.Vendor">
                                    <option value=""></option>
                                    <option v-for="item in vendors" :key="item.OptionsName_zhTW" :value="item.OptionsName_zhTW">{{ item.OptionsName_zhTW }}</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-2">
                            <div class="form-group">
                                <label>起始日期</label>

                                <el-date-picker type="date" placeholder="請輸入日期" value-format="yyyy-MM-dd" class="date" v-model="searchInfo.StartDate">
                                </el-date-picker>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-2">
                            <div class="form-group">
                                <label>結束日期</label>

                                <el-date-picker type="date" placeholder="請輸入日期" value-format="yyyy-MM-dd" class="date" v-model="searchInfo.EndDate">
                                </el-date-picker>
                            </div>
                        </div>
            

                        <div class="col-sm-12 col-md-2">
                            <button
                                type="button"
                                class="btn btn-primary btn-search"
                                style="margin-top: 30px; margin-left: 10px"
                                @click="handleSubmit(getExternalHealthQuestionnaireList(1, tableInfo.per_page))"
                            >
                                查詢
                            </button>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
        </div>

        <div class="yahome-table-wrap limit">
            <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-info mb-2" @click="exportFile">匯出</button>
            </div>
            <VueBootstrap4Table
                :rows="rows"
                :columns="columns"
                :config="config"
                class="opening-table"
                @on-change-query="onChangeQuery"
                :total-rows="total"
            >

                <template slot="NoCardId" slot-scope="scope">
                    <div class="limit-width" :title="scope.row.NoCardId">
                        {{ scope.row.NoCardId }}
                    </div>
                </template>
                <template slot="CompanyName" slot-scope="scope">
                    <div class="limit-width" :title="scope.row.CompanyName">
                        {{ scope.row.CompanyName }}
                    </div>
                </template>
                <template slot="Name" slot-scope="scope">
                    <div class="limit-width" :title="scope.row.Name">
                        {{ scope.row.Name }}
                    </div>
                </template>
                <template slot="CurrentResidencePlace" slot-scope="scope">
                    <div class="limit-width" :title="scope.row.CurrentResidencePlace">
                        {{ scope.row.CurrentResidencePlace }}
                    </div>
                </template>

                <!-- 明細 -->
                <template slot="Detail" slot-scope="scope">
                    <button
                        type="button"
                        class="btn btn-primary"
                        @click.prevent="viewDetail(scope.row.Guid)"
                        v-if="scope.row.CreateTime !== null"
                    >
                        明細
                    </button>

                    <div v-else style="height: 38px"></div>
                </template>
                <template slot="empty-results"> 暫無資料 </template>
            </VueBootstrap4Table>
        </div>

        <Detail />

    </div>
</template>

<script>
/* global $ */
import moment from "moment";
// import QrcodeVue from "qrcode.vue";
// 複製
// import Clipboard from "clipboard";

// import DemandDetailModal from "@/components/back/Modal/DemandDetail";

import { config } from "@/components/table_config.js";

import Detail from '@/components/Modal/externalHealthDetail.vue'

import { apiGetExternalHealthQuestionnaireList, apiExportExternalHealthQuestionnaire } from '@/apis/questionnaire'
import { mapActions } from 'vuex'

export default {
    name: "OpeningManage",
    components: {
        Detail,
    },
    data() {
        return {
            rows: [],
            columns: [
                {
                    label: "填寫時間",
                    name: "CreateTime",
                },
                {
                    label: "黃卡編號",
                    name: "NoCardId",
                    slot_name: "NoCardId",
                },
                {
                    label: "公司名",
                    name: "CompanyName",
                    slot_name: "CompanyName",
                },
                {
                    label: "姓名",
                    name: "Name",
                    slot_name: "Name",
                },
                {
                    label: "居住地",
                    name: "CurrentResidencePlace",
                    slot_name: "CurrentResidencePlace",
                },
                {
                    label: "訪客類別",
                    name: "VisitorType",
                },
                {
                    label: "駐廠廠商",
                    name: "Vendor",
                },
                {
                    label: "檢視明細",
                    name: "Detail",
                    slot_name: "Detail",
                },
            ],
            visitorTypes: [
                {
                    OptionsName_zhTW: '客戶',
                    OptionsName_enUS: 'Customer'
                },
                {
                    OptionsName_zhTW: '訪客',
                    OptionsName_enUS: 'Visitor'
                },
                {
                    OptionsName_zhTW: '送提貨廠商',
                    OptionsName_enUS: 'Pick up/Deliver the goods'
                },
                {
                    OptionsName_zhTW: '駐廠廠商',
                    OptionsName_enUS: 'Factory vendor'
                },
                {
                    OptionsName_zhTW: '設備商',
                    OptionsName_enUS: 'Machine supplier'
                },
                {
                    OptionsName_zhTW: '施工廠商',
                    OptionsName_enUS: 'Construct supplier'
                },
                {
                    OptionsName_zhTW: '求職者',
                    OptionsName_enUS: 'Applicants for apply jobs'
                },
                {
                    OptionsName_zhTW: '新人報到',
                    OptionsName_enUS: 'New Employee'
                },
            ],
            vendors: [
                {
                    OptionsName_zhTW: '嘉苗',
                    OptionsName_enUS: 'Customer'
                },
                {
                    OptionsName_zhTW: '威合',
                    OptionsName_enUS: 'Customer'
                },
                {
                    OptionsName_zhTW: '歐艾斯',
                    OptionsName_enUS: 'Customer'
                },
                {
                    OptionsName_zhTW: '北氧',
                    OptionsName_enUS: 'Customer'
                },
                {
                    OptionsName_zhTW: '田中',
                    OptionsName_enUS: 'Customer'
                },
            ],
            config: {
                ...config,
                server_mode: true,
            },
            searchInfo: {
                Date: '',
            },
            tableInfo: {
                // 分頁資訊
                page: 1,
                per_page: 10,
            },
            // questionnaire: {},
            choiceGuid: '',
            total: 0,
        };
    },
    filters: {
        moment: function(date) {
            return moment(date).format("YYYY-MM-DD");
        },
    },

    methods: {
        ...mapActions(['updateLoading']),
        ///////////////////////////  多國語系  ///////////////////////////////////////////
        i18n(keyStr) {
            return this.$t(keyStr);
        },
        getExternalHealthQuestionnaireList(page = 1, per_page = 10) {

            this.updateLoading(true)
            apiGetExternalHealthQuestionnaireList({
                ...this.searchInfo,
                Page: page,
                PerPage: per_page,
            }).then((response) => {

                this.total = response.data.DataQuantity

                this.rows = response.data.Dtos.map(item => {
                    return {
                        ...item,
                        CreateTime: item.CreateTime !== null ? moment(item.CreateTime).format("YYYY-MM-DD HH:mm:ss") : null,
                    }
                })

                this.updateLoading(false)
            })
        },
        onChangeQuery(queryParams) {
            const vm = this;

            vm.tableInfo = {
                page: queryParams.page,
                per_page: queryParams.per_page,
            };

            vm.getExternalHealthQuestionnaireList(
                vm.tableInfo.page,
                vm.tableInfo.per_page,
            );
        },
        viewDetail(Guid) {

            this.$store.dispatch("updateWatchGuid", Guid);

        },
        exportFile() {

            this.updateLoading(true)
            apiExportExternalHealthQuestionnaire({
                ...this.searchInfo,
            }).then((response) => {
                if(response.status === 200) {
                    const blob = new Blob([response.data], { 'type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    a.href = url;
                    a.download = `外部疫調報表.xlsx`;
                    a.click();
                    window.URL.revokeObjectURL(url);

                    this.updateLoading(false)
                }
            })
        }
    },
    created() {
        this.searchInfo.Date = moment().format("YYYY-MM-DD");
    },
    mounted() {
        this.getExternalHealthQuestionnaireList()
    },

    destroyed() {
        $(".modal").modal("hide");
        $("body").removeClass("modal-open");
        $(".modal-backdrop").remove();
    },
};
</script>

<style lang="scss" scoped>
.detail-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}

.textarea {
    min-height: 140px;
}

.date {
    display: block;
}

.el-date-editor.el-input {
    width: 100%;
}


</style>
